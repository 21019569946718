import { useTranslation } from "next-i18next"
import CONSTANTS from "@/constants"
import parse from "html-react-parser"
const useAuthModali18n = props => {
  const { t } = useTranslation()
  const i18n = {
    createAccountMobileNumber:
      parse(`${props?.createAccountMobileNumber}`) === CONSTANTS.UNDEFINED
        ? t("Mobile Number*")
        : parse(
            `${props?.createAccountMobileNumber}`.replaceAll("&amp;nbsp;", " ")
          ),
    createAccountFormIAgreeText:
      parse(
        `${props?.createAccountFormIAgreeText}`.replaceAll("&amp;nbsp;", " ")
      ) ?? "",
    phoneExists: t("kf.auth.label.createaccount.error.phoneexits"),
    signin:
      parse(`${props?.signIn}`) === CONSTANTS.UNDEFINED
        ? t("Sign In")
        : parse(`${props?.signIn}`.replaceAll("&amp;nbsp;", " ")),
    signinWithOTP:
      parse(`${props?.loginMainTitle}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.signinWithOTP")
        : parse(`${props?.loginMainTitle}`.replaceAll("&amp;nbsp;", " ")),
    createAccount:
      parse(`${props?.createAccount}`) === CONSTANTS.UNDEFINED
        ? t("Create Account")
        : parse(`${props?.createAccount}`.replaceAll("&amp;nbsp;", " ")),
    enterMobileNumber: t("kf.login.enterMobileNumber"),
    getOTP:
      parse(`${props?.loginCtaLabel}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.getOTP")
        : parse(`${props?.loginCtaLabel}`.replaceAll("&amp;nbsp;", " ")),
    policyText: parse(
      `${props?.loginPrivacyPolicyText}`.replaceAll("&amp;nbsp;", " ")
    ),
    label:
      parse(`${props?.otpFlowScreen2Description}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.label")
        : props?.otpFlowScreen2Description.replace(
            /(<p[^>]+?>|<p>|<\/p>)/gim,
            ""
          ),
    backToSignIn:
      parse(`${props?.loginBackButtonText}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.backToSignIn")
        : parse(`${props?.loginBackButtonText}`.replaceAll("&amp;nbsp;", " ")),
    verifySignIn:
      parse(`${props?.loginScreen2ctaLabel}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.verify")
        : parse(`${props?.loginScreen2ctaLabel}`.replaceAll("&amp;nbsp;", " ")),
    otpNotReceived:
      parse(`${props?.loginResendOtpText}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.otpNotReceived")
        : parse(`${props?.loginResendOtpText}`.replaceAll("&amp;nbsp;", " ")),
    resendOTP:
      parse(`${props?.loginResendOtpLabel}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.resendOTP")
        : parse(`${props?.loginResendOtpLabel}`.replaceAll("&amp;nbsp;", " ")),
    tryAgain:
      parse(`${props?.loginExceededAttemptText}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.tryAgain")
        : parse(
            `${props?.loginExceededAttemptText}`.replaceAll("&amp;nbsp;", " ")
          ),
    exitConfirmation:
      parse(`${props?.loginConfirmationText}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.exitConfirmation")
        : parse(
            `${props?.loginConfirmationText}`.replaceAll("&amp;nbsp;", " ")
          ),
    yes: t("kf.contactus.label.yes"),
    no: t("kf.contactus.label.no"),
    tryagainmin: t("kf.login.tryAgainmin"),
    errorPhoneNumber: t("kf.login.errorPhoneNumber"),
    signinDescription: t("kf.signin.desc"),
    invalidOTP:
      parse(`${props?.loginOtpErrorMessage}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.invalidOTP")
        : parse(`${props?.loginOtpErrorMessage}`.replaceAll("&amp;nbsp;", " ")),

    mobileDescription: t("kf.auth.label.createaccount.mobileDescription"),
    resend: t("kf.auth.label.createaccount.resend"),
    otpexpire: t("kf.signin.otpexpire"),
    receiveOTP: t("kf.auth.label.createaccount.receiveOTP"),
    getOTP: t("kf.auth.label.createaccount.getOTP"),
    verify: t("kf.auth.label.createaccount.verify"),
    errorOTP: t("kf.auth.label.createaccount.verifyOTP"),
    exceedlimit: t("kf.auth.label.createaccount.exceedlimit"),
    changeNumber:
      parse(`${props?.otpFlowScreen2MobilePlaceholder}`) === CONSTANTS.UNDEFINED
        ? t("kf.login.changeNumber")
        : parse(
            `${props?.otpFlowScreen2MobilePlaceholder}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    backToCreate: t("kf.auth.label.createaccount.backToCreateAccount"),
    createTitle: t("kf.auth.label.createaccount.title"),
    termsCondition: t("kf.auth.label.createaccount.terms"),
    signin:
      parse(`${props?.signIn}`) === CONSTANTS.UNDEFINED
        ? t("Sign In")
        : parse(`${props?.signIn}`.replaceAll("&amp;nbsp;", " ")),
    createAccount:
      parse(`${props?.createAccount}`) === CONSTANTS.UNDEFINED
        ? t("Create Account")
        : parse(`${props?.createAccount}`.replaceAll("&amp;nbsp;", " ")),
    createYourAccount:
      parse(`${props?.createAccountFormTitle}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.createYourAccount")
        : parse(
            `${props?.createAccountFormTitle}`.replaceAll("&amp;nbsp;", " ")
          ),
    email: t("kf.auth.label.email"),
    password: t("kf.auth.label.password"),
    homeowneremail:
      parse(`${props?.homeowneremail}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.emailAddress")
        : parse(`${props?.homeowneremail}`.replaceAll("&amp;nbsp;", " ")),
    homeownerpassword:
      parse(`${props?.homeownerpassword}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.homeownerpassword")
        : parse(`${props?.homeownerpassword}`.replaceAll("&amp;nbsp;", " ")),
    forgotPassword: t("kf.auth.label.forgotPassword"),
    keepMeLoggedIn:
      parse(`${props?.keepMeLoggedIn}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.keepMeLoggedIn")
        : parse(`${props?.keepMeLoggedIn}`.replaceAll("&amp;nbsp;", " ")),
    loginWithFacebook: t("kf.auth.label.loginWithFacebook"),
    loginWithGoogle: t("kf.auth.label.loginWithGoogle"),
    loginWithApple: t("kf.auth.label.loginWithApple"),
    createAccountDesc:
      parse(`${props?.createAccountDesc}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.createAccountDesc")
        : parse(`${props?.createAccountDesc}`.replaceAll("&amp;nbsp;", " ")),
    subjectAccessReq:
      parse(`${props?.subjectAccessReq}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.subjectAccessReq")
        : parse(`${props?.subjectAccessReq}`.replaceAll("&amp;nbsp;", " ")),
    homeOwnerAccount:
      parse(`${props?.homeOwnerAccount}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.homeOwnerAccount")
        : parse(`${props?.homeOwnerAccount}`.replaceAll("&amp;nbsp;", " ")),
    homeOwnerAccountDesc:
      parse(`${props?.homeOwnerAccountDesc}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.homeOwnerAccountDesc")
        : parse(`${props?.homeOwnerAccountDesc}`.replaceAll("&amp;nbsp;", " ")),
    professionalAccount:
      parse(`${props?.professionalAccount}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.professionalAccount")
        : parse(`${props?.professionalAccount}`.replaceAll("&amp;nbsp;", " ")),
    professionalAccountDesc: parse(`${props?.professionalAccountDesc}`)
      ? t("kf.auth.label.professionalAccountDesc")
      : parse(
          `${props?.professionalAccountDesc}`.replaceAll("&amp;nbsp;", " ")
        ),
    privacyPolicy:
      parse(`${props?.privacypolicy}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.privacyPolicy")
        : parse(`${props?.privacypolicy}`.replaceAll("&amp;nbsp;", " ")),
    termsOfService:
      parse(`${props?.termsofservice}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.termsOfService")
        : parse(`${props?.termsofservice}`.replaceAll("&amp;nbsp;", " ")),
    personalRecommendations:
      parse(`${props?.recommendations}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.personalRecommendations")
        : parse(`${props?.recommendations}`.replaceAll("&amp;nbsp;", " ")),
    accessToFeatures:
      parse(`${props?.projectplanningfeatures}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.accessToFeatures")
        : parse(
            `${props?.projectplanningfeatures}`.replaceAll("&amp;nbsp;", " ")
          ),
    savePaymentsAndAddress:
      parse(`${props?.preferences}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.savePaymentsAndAddress")
        : parse(`${props?.preferences}`.replaceAll("&amp;nbsp;", " ")),
    quickCheckout:
      parse(`${props?.checkoutfaster}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.quickCheckout")
        : parse(`${props?.checkoutfaster}`.replaceAll("&amp;nbsp;", " ")),
    easyOrderTracking:
      parse(`${props?.easilytrackorders}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.easyOrderTracking")
        : parse(`${props?.easilytrackorders}`.replaceAll("&amp;nbsp;", " ")),
    requiredFields:
      parse(`${props?.fieldnamerequired}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.requiredFields")
        : parse(`${props?.fieldnamerequired}`.replaceAll("&amp;nbsp;", " ")),
    validEmail:
      parse(`${props?.homeowneremailError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.validEmail")
        : parse(`${props?.homeowneremailError}`.replaceAll("&amp;nbsp;", " ")),
    professionalaccemailError:
      parse(`${props?.professionalaccemailError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.validEmail")
        : parse(
            `${props?.professionalaccemailError}`.replaceAll("&amp;nbsp;", " ")
          ),
    validPassword: t("kf.auth.error.validPassword"),
    invalidEmailOrPassword:
      parse(`${props?.invalidEmailOrPassword}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.invalidEmailOrPassword")
        : parse(
            `${props?.invalidEmailOrPassword}`.replaceAll("&amp;nbsp;", " ")
          ),
    lockedOut: t("kf.auth.error.lockedOut"),
    contactSupport: t("kf.auth.error.contactSupport"),
    mobileLabel: t("kf.auth.label.createaccount.mobileNumber"),
    mobilenumber: t("kf.auth.label.createaccount.mobilenumber"),
    fnameError:
      parse(`${props?.homeownerfirstnameError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.fnameErrorNew")
        : parse(
            `${props?.homeownerfirstnameError}`.replaceAll("&amp;nbsp;", " ")
          ),
    professionalaccfirstnameError:
      parse(`${props?.professionalaccfirstnameError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.fnameErrorNew")
        : parse(
            `${props?.professionalaccfirstnameError}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    lnameError:
      parse(`${props?.homeownerLastNameError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.lnameError")
        : parse(
            `${props?.homeownerLastNameError}`.replaceAll("&amp;nbsp;", " ")
          ),
    professionalacclastnameError:
      parse(`${props?.professionalacclastnameError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.lnameError")
        : parse(
            `${props?.professionalacclastnameError}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    passwordError:
      parse(`${props?.homeownerpasswordError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.passwordError")
        : parse(
            `${props?.homeownerpasswordError}`.replaceAll("&amp;nbsp;", " ")
          ),
    passwordMatchError:
      parse(`${props?.passwordMismatcherror}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.passwordMatchError")
        : parse(
            `${props?.passwordMismatcherror}`.replaceAll("&amp;nbsp;", " ")
          ),
    zipcodeError: t("kf.auth.error.zipcodeError"),
    validZipcode: t("kf.auth.error.validZipcode"),
    businessTypeError: t("kf.auth.error.businessTypeError"),
    subTypeError: t("kf.auth.error.subTypeError"),
    homeownerfirstname:
      parse(`${props?.homeownerfirstname}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.firstName")
        : parse(`${props?.homeownerfirstname}`.replaceAll("&amp;nbsp;", " ")),
    homeownerLastName:
      parse(`${props?.homeownerLastName}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.lastName")
        : parse(`${props?.homeownerLastName}`.replaceAll("&amp;nbsp;", " ")),
    zipcode: t("kf.auth.label.zipcode"),
    companyName: t("kf.auth.label.companyName"),
    companyNameError:
      parse(`${props?.companyNameError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.companyNameError")
        : parse(`${props?.companyNameError}`.replaceAll("&amp;nbsp;", " ")),
    companyNameAlphanumericError:
      parse(`${props?.companyNameAlphanumericError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.companyNameAlphanumericError")
        : parse(
            `${props?.companyNameAlphanumericError}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    emailAddress: t("kf.auth.label.emailAddress"),
    passwordRequirements:
      parse(`${props?.passwordRequirements}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.passwordRequirements")
        : parse(`${props?.passwordRequirements}`.replaceAll("&amp;nbsp;", " ")),
    primaryBusinessType: t("kf.auth.label.primaryBusinessType"),
    accountSubType: t("kf.auth.label.accountSubType"),
    signupForNewsletter:
      parse(`${props?.newsletterSignUp}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.signupForNewsletter")
        : parse(`${props?.newsletterSignUp}`.replaceAll("&amp;nbsp;", " ")),
    alreadyHaveAccount:
      parse(`${props?.alreadyHaveAnAccount}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.alreadyHaveAccount")
        : parse(`${props?.alreadyHaveAnAccount}`.replaceAll("&amp;nbsp;", " ")),
    pwMustInclude:
      parse(`${props?.authorablepassworddialog}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.pwMustInclude")
        : parse(
            `${props?.authorablepassworddialog}`.replaceAll("&amp;nbsp;", " ")
          ),
    showDialog: false,
    welcomeTo: props?.hi ?? t("kf.auth.label.welcomeTo"),
    welcomeMessage: props?.accountSuccessDesc,
    checkoutWelcomeMessage: t("kf.auth.label.welcomeMessage"),
    finishProfile: t("kf.auth.label.finishProfile"),
    accountSuccessCTA: props?.accountSuccessCTA,
    accountSuccessLink: props?.accountSuccessLink,
    continueShopping: t("kf.auth.label.continueShopping"),
    sendEmail: t("kf.auth.label.sendEmail"),
    resendEmail: t("kf.auth.label.resendEmail"),
    continueShipping: t("kf.checkout.label.continuetoshipping"),
    otpDescription: t("kf.checkout.label.customerInstruction"),
    backToSignin:
      parse(`${props?.backToSignIn}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.backToSignin")
        : parse(`${props?.backToSignIn}`.replaceAll("&amp;nbsp;", " ")),
    resetPassword:
      parse(`${props?.resetPasswordTitle}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.resetPassword")
        : parse(`${props?.resetPasswordTitle}`.replaceAll("&amp;nbsp;", " ")),
    mailSent:
      parse(`${props?.resetPasswordStep2Title}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.mailSent")
        : parse(
            `${props?.resetPasswordStep2Title}`.replaceAll("&amp;nbsp;", " ")
          ),
    checkEmail:
      parse(`${props?.resetPasswordStep2Desc}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.checkEmail")
        : parse(
            `${props?.resetPasswordStep2Desc}`.replaceAll("&amp;nbsp;", " ")
          ),
    forgotPasswordDesc:
      parse(`${props?.resetPasswordDesc}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.forgotPasswordDesc")
        : parse(`${props?.resetPasswordDesc}`.replaceAll("&amp;nbsp;", " ")),
    emailAlreadyExists: t("kf.auth.error.emailAlreadyExists"),
    submit: t("kf.auth.label.submit"),
    close: t("kf.auth.label.close"),
    signinAria: t("kf.auth.label.signinAria"),
    createAccountAria: t("kf.auth.label.createAccountAria"),
    homeOwnerAria: t("kf.auth.label.homeOwnerAria"),
    proAccountAria: t("kf.auth.label.proAccountAria"),
    authModalAria: t("kf.auth.label.authModalAria"),
    signupModalAria: t("kf.auth.label.signupModalAria"),
    forgotPwdModalAria: t("kf.auth.label.forgotPwdModalAria"),
    welcomeAria: t("kf.auth.label.welcomeAria"),
    pwdError:
      parse(`${props?.homeownerpasswordError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.provide.pwdError")
        : parse(
            `${props?.homeownerpasswordError}`.replaceAll("&amp;nbsp;", " ")
          ),
    reEnterPwdError:
      parse(`${props?.homeownerreenterpasswordError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.provide.reEnterPwdError")
        : parse(
            `${props?.homeownerreenterpasswordError}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    professionalaccpasswordError:
      parse(`${props?.professionalaccpasswordError}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.provide.pwdError")
        : parse(
            `${props?.professionalaccpasswordError}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    professionalaccreenterpasswordError:
      parse(`${props?.professionalaccreenterpasswordError}`) ===
      CONSTANTS.UNDEFINED
        ? t("kf.auth.label.provide.pwdError")
        : parse(
            `${props?.professionalaccreenterpasswordError}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    signinOrSignup: t("kf.cart.label.signinOrSignup"),
    generalAuthenticationError: t("kf.auth.error.generalAuthenticationError"),
    homeOwnerAccountCTA:
      parse(`${props?.homeOwnerAccountCTA}`) === CONSTANTS.UNDEFINED
        ? t("Create Account")
        : parse(`${props?.homeOwnerAccountCTA}`.replaceAll("&amp;nbsp;", " ")),
    professionalAccountCTA:
      parse(`${props?.professionalAccountCTA}`) === CONSTANTS.UNDEFINED
        ? t("Create Account")
        : parse(
            `${props?.professionalAccountCTA}`.replaceAll("&amp;nbsp;", " ")
          ),
    proUserWelcomeMsg: t("kf.auth.label.proUserWelcomeMessage"),
    proUserSuccessCTA: t("kf.auth.label.proUserSuccessCTA"),
    professionalaccfirstname:
      parse(`${props?.professionalaccfirstname}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.firstName")
        : parse(
            `${props?.professionalaccfirstname}`.replaceAll("&amp;nbsp;", " ")
          ),
    professionalacclastname:
      parse(`${props?.professionalacclastname}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.lastName")
        : parse(
            `${props?.professionalacclastname}`.replaceAll("&amp;nbsp;", " ")
          ),
    professionalaccemail:
      parse(`${props?.professionalaccemail}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.emailAddress")
        : parse(`${props?.professionalaccemail}`.replaceAll("&amp;nbsp;", " ")),
    signinhyperlink:
      `${props?.signinhyperlink}` === CONSTANTS.UNDEFINED
        ? ""
        : `${props?.signinhyperlink}`,
    homeownerreenterpassword:
      parse(`${props?.homeownerreenterpassword}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.reEnterPassword")
        : parse(
            `${props?.homeownerreenterpassword}`.replaceAll("&amp;nbsp;", " ")
          ),
    professionalaccpassword:
      parse(`${props?.professionalaccpassword}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.homeownerpassword")
        : parse(
            `${props?.professionalaccpassword}`.replaceAll("&amp;nbsp;", " ")
          ),
    professionalaccreenterpassword:
      parse(`${props?.professionalaccreenterpassword}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.label.reEnterPassword")
        : parse(
            `${props?.professionalaccreenterpassword}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    signInValidEmail:
      parse(`${props?.invalidEmail}`) === CONSTANTS.UNDEFINED
        ? t("kf.auth.error.validEmail")
        : parse(`${props?.invalidEmail}`.replaceAll("&amp;nbsp;", " ")),
    regexForSiginPassword: parse(
      `${props?.regexForPassword}`.replaceAll("&amp;nbsp;", " ")
    ),
    maxStock: quantity =>
      `Only ${quantity} items available in stock. Please update the quantity to proceed.`,
    existEmailAddress: mobileNo =>
      t("kf.checkout.label.existEmail", { mobileNo }),
    errorMobileNumber: t("kf.customerSupport.error.validMobileNumber"),
    confirmationmodal: t("kf.checkout.label.confirmationmodal"),
    signinPasswordFormatError: parse(
      `${props?.passwordFormatError}`.replaceAll("&amp;nbsp;", " ")
    ),
    createAccountPrivacyPolicy: parse(
      `${props?.createAccountScreen2PrivacyPoilcy}`.replaceAll(
        "&amp;nbsp;",
        " "
      )
    ),
    verifyMobileNumber:
      parse(`${props?.otpFlowDescription}`) === CONSTANTS.UNDEFINED
        ? t("kf.customerSupport.message.verifyPhone")
        : parse(`${props?.otpFlowDescription}`.replaceAll("&amp;nbsp;", " ")),
    mobileVerification:
      parse(`${props?.otpFlowMainTitle}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFLow.mobileVerification")
        : parse(`${props?.otpFlowMainTitle}`.replaceAll("&amp;nbsp;", " ")),
    verificationTitle: t("kf.auth.label.verificationTitle"),
    otpTitle: t("kf.otpFlow.otpTitle"),
    changeMobileTitle: t("kf.otpFlow.changeMobileTitle"),
    changeMobileDescription: t("kf.login.changeMobileDescription"),
    verificationDescription: "kf.auth.label.verificationDescription",
    signupTtile: t("kf.auth.label.signupTitle"),
    updateNumber: t("kf.otpFlow.updateNumber"),
    numberAlreadyExists: t("kf.otpFlow.numberAlreadyExists"),
    numberNotRegistered: t("kf.auth.label.numberNotRegistered"),
    mobileNumber: t("kf.otpFlow.mobileNumber"),
    close: t("kf.findStoreUtilNav.label.closeAlt"),
    numberNotRegistered: t("kf.auth.label.numberNotRegistered"),
    creatingAnAccount: t("kf.auth.label.creatingAnAccount"),
    otpSentDescription:
      parse(`${props?.sixDigitOtpText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.otpSentDescription")
        : parse(`${props?.sixDigitOtpText}`.replaceAll("&amp;nbsp;", " ")),
    changeMobileNumberText:
      parse(`${props?.changeMobileNumberText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.changeMobileNumberText")
        : parse(
            `${props?.changeMobileNumberText}`.replaceAll("&amp;nbsp;", " ")
          ),
    otpExpirationTime:
      parse(`${props?.otpExpireDurationText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.otpExpirationTime")
        : parse(
            `${props?.otpExpireDurationText}`.replaceAll("&amp;nbsp;", " ")
          ),
    otpExpirationText:
      parse(`${props?.otpExpirationText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.otpExpirationText")
        : parse(`${props?.otpExpirationText}`.replaceAll("&amp;nbsp;", " ")),
    otpInvalidText:
      parse(`${props?.otpInvalidText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.otpInvalidText")
        : parse(`${props?.otpInvalidText}`.replaceAll("&amp;nbsp;", " ")),
    limitExceededText:
      parse(`${props?.limitExceededText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.limitExceededText")
        : parse(`${props?.limitExceededText}`.replaceAll("&amp;nbsp;", " ")),
    otpCantSendText:
      parse(`${props?.otpCantSendText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.otpCantSendText")
        : parse(`${props?.otpCantSendText}`.replaceAll("&amp;nbsp;", " ")),
    tryAgainText:
      parse(`${props?.tryAgainText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.tryAgainText")
        : parse(`${props?.tryAgainText}`.replaceAll("&amp;nbsp;", " ")),
    mobileNumberChangedSuccessText:
      parse(`${props?.mobileNumberChangedSuccessText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.mobileNumberChangedSuccessText")
        : parse(
            `${props?.mobileNumberChangedSuccessText}`.replaceAll(
              "&amp;nbsp;",
              " "
            )
          ),
    profileCompletionText:
      parse(`${props?.profileCompletionText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.profileCompletionText")
        : parse(
            `${props?.profileCompletionText}`.replaceAll("&amp;nbsp;", " ")
          ),
    viewMyAccountText:
      parse(`${props?.viewMyAccountText}`) === CONSTANTS.UNDEFINED
        ? t("kf.otpFlow.viewMyAccountText")
        : parse(`${props?.viewMyAccountText}`.replaceAll("&amp;nbsp;", " ")),
    noEmailError: t("kf.contactus.error.noEmailError"),
    activateAccountTitle: t("kf.otpFlow.activateAccountTitle"),
    activateAccountDesc: t("kf.otpFlow.activateAccountDesc"),
    activateAccountCta: t("kf.otpFlow.activateAccountCta"),
  }
  if (i18n.pwMustInclude === t("kf.auth.label.pwMustInclude")) {
    i18n.showDialog = true
    i18n.characterCount = t("kf.auth.label.characterCount")
    i18n.uppercaseLetter = t("kf.auth.label.uppercaseLetter")
    i18n.lowercaseLetter = t("kf.auth.label.lowercaseLetter")
    i18n.symbolValidation = t("kf.auth.label.symbolValidation")
    i18n.numberValidation = t("kf.auth.label.numberValidation")
  }
  return i18n
}
export default useAuthModali18n
